import React from "react";
import "./whoweare.css";
import { Link } from "react-router-dom";
import Fit from "../../assets/PandM.jpg";
import Mission from "../../assets/church.jpg";

const Whoweare = () => {
  return (
    <div className="whoweare">
      <div className="whoweare__intro">
        <h4>WHO WE ARE</h4>
        <p>
          Golden Girls on the Move wants to create a society where women are
          encouraged to be active in sports and to support them in their
          physical, mental, spiritual and social needs. Our target is not only
          limited to encouraging women aged 50+ to be physically active but also
          to support young sportswomen across all sporting activities
        </p>
        <button className="whoweare__introBtn">
          <a href="/about">READ MORE ABOUT US</a>
        </button>
      </div>
      <h4 className="whoweare__visionH4">OUR VISION</h4>
      <div className="whoweare__vision">
        <div className="whoweare__visionImgDiv">
          <img
            src={Mission}
            alt="whoweare__visionImg"
            className="whoweare__visionImg"
          />
        </div>
        <div className="whoweare__visionTxt">
          <h4>OUR VISION</h4>
          <span>
            <br />
            <i>
              <b>"A nation with vibrant sportswomen at all ages."</b>
            </i>
          </span>
          <p>
            Here at GG-M we strive to support women of all ages who participate
            or are planning to get involve in any sport.
          </p>
          <button className="whoweare__visionBtn">
            <a href="/about">MORE DETAILS</a>
          </button>
        </div>
      </div>
      <div className="whoweare__mission">
        <div className="whoweare__missionTxt">
          <h4>OUR MISSION</h4>
          <span>
            <i>
              <b>
                "To nurture the active participation of women 50+ in sports
                while supporting young sportswomen to thrive in Kenya."
              </b>
            </i>
          </span>
          <p>
            Our registered members are women aged 50 or older seeking to be
            active in sports as well as supporting other sportswomen across all
            ages by supporting various sporting organizations and
            running campaigns addressing challenges facing women
          </p>
          <button className="whoweare__missionBtn">
            <Link to="/about">READ MORE</Link>
          </button>
        </div>
        <div className="whoweare__missionImgDiv">
          <img
            src={Fit}
            alt="whoweare__missionImg"
            className="whoweare__missionImg"
          />
        </div>
      </div>
      {/* Goals */}
      <div className="goals">
        <div className="ourGoals">
          <h4 className="ourGoalsHead">What we can achieve together</h4>
          <div className="goalsList">
            <div className="ourGoalsListTxt">
              <p>
                Golden Girls on the move is not just another Sports Club; we
                understand the change being physically active can bring to a
                woman's life. Together we can:
              </p>
            </div>
            <div className="objectiveBars">
              <div className="objectiveBar">
                {/* <i class="fa-solid fa-user-group"></i> */}
                <i class="fa-solid fa-users"></i>
                Organize events/forums for members
              </div>
              <div className="objectiveBar"><i class="fa-solid fa-hand-holding-heart"></i>Nurture young sportswomen</div>
              <div className="objectiveBar">
                <i class="fa-solid fa-medal"></i>
                Organize regional and national sports events for women
              </div>
              <div className="objectiveBar">
                <i class="fa-solid fa-briefcase"></i>
                Advocate for women in sports at all levels
              </div>
              <div className="objectiveBar">
                <i class="fa-solid fa-handshake-angle"></i>
                Support counties to develop initiatives for women 50+ years
              </div>
            </div>
            <div className="goalsBtnDiv">
              <button className="goalsBtn">
                <a href="/about">LEARN MORE</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whoweare;
