import React, { useEffect, useState } from "react";
import "./60@60.css";
import VideoJS from "./video.js";

const SixtyBySixty = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  // const [formData, setFormData] = useState({
  //   firstname: "",
  //   lastname: "",
  //   email: "",
  //   phonenumber: "",
  //   role: "",
  //   distance: "",
  //   numberOfKids: "",
  //   shirtSIze: null,
  //   emergencyContactName: "",
  //   emergencyContactNumber: "",
  //   hearAbout: null,
  //   paymentConfirmationMessage: null,
  //   termsandConditions: null,
  //   additionalInfo: "",
  //   childrenData: [],
  // });

  // const handleChange = ((e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // })

  const handleSwitch = (identifier) => {
    if (identifier === "event_details") {
      if (document.querySelector(".event_details").classList.contains("active")) {
        return;
      }
      document.querySelector(".detailsHeader").classList.add("active");
      document.querySelector(".event_details").classList.add("active");
      document.querySelector(".register_underline").classList.remove("active");
      document.querySelector(".details_underline").classList.add("active");
      document.querySelector(".registrationHeader").classList.remove("active");
      document.querySelector(".registration_form").classList.remove("active");
    } else {
      if (document.querySelector(".registration_form").classList.contains("active")) {
        return;
      }
      document.querySelector(".register_underline").classList.add("active");
      document.querySelector(".details_underline").classList.remove("active");
      document.querySelector(".registrationHeader").classList.add("active");
      document.querySelector(".detailsHeader").classList.remove("active");
      document.querySelector(".event_details").classList.remove("active");
      document.querySelector(".registration_form").classList.add("active");
    }
  }

  useEffect(() => {
    if (window.google) {
      setMapLoaded(true);
    } else {
      console.error("Google Maps API failed to load.");
    }
  }, []);

  useEffect(() => {
    const initMap = (lat, lng) => {
      if (!window.google || !window.google.maps) {
        console.error("Google Maps API is not loaded.");
        return;
      }

      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat, lng },
        zoom: 15,
      });

      new window.google.maps.Marker({
        position: { lat, lng },
        map,
        title: "Your Location",
      });
    };
    initMap(-1.3557197, 36.7395531);
  }, [mapLoaded]);

  // const playerRef = React.useRef(null);

  // const videoJsOptions = {
  //   autoplay: true,
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   sources: [{
  //     src: '../../src/assets/mary@60/VID-20250304-WA0006.mp4',
  //     type: 'video/mp4'
  //   }]
  // };

  // const handlePlayerReady = (player) => {
  //   playerRef.current = player;

  //   // You can handle player events here, for example:
  //   player.on('waiting', () => {
  //     videojs.log('player is waiting');
  //   });

  //   player.on('dispose', () => {
  //     videojs.log('player will dispose');
  //   });
  // };

  return (
    <div className="sixtyAtSixty">
      <div className="sixty_header_container">
        <h1 className="sixty_header">Mary's Ultra Run</h1>
        <h3 className="sixty_subheader">60@60</h3>
        <div className="disclamer">
          <div className="disclaimerSpanDiv">
            <span>All proceed will go to supporting the mental health of women aged 50+ including raising awareness on dementia.</span>
          </div>
        </div>
      </div>
      <h3 className="theme"><i>"Miles for Mental Health"</i></h3>
      <div className="intro_para">
        <p>
          Join us on 9<sup>th</sup> March 2025 at JKUAT Karen Campus as one of our
          members, Mary Amuyunzu, celebrates her 60 <sup>th</sup> birthday with a
          memorable run. All proceeds from this event will be used in raising
          awareness on mental health and dementia in line with the mission and
          vision of this organization.
        </p>
      </div>
      {/* <div>
        <h3 className="video_header">Event Video</h3>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div> */}
      <div className="event_details_registration">
        <div className="headers">
          <div className="registrationHeader active" onClick={() => { handleSwitch("registration_form") }}>
            <h3>Registration Form</h3>
            <div className="register_underline"></div>
          </div>
          <div className="detailsHeader" onClick={() => { handleSwitch("event_details") }}>
            <h3>Event Details</h3>
            <div className="details_underline"></div>
          </div>
        </div>
        <div className="registration_form active">
          <iframe
            title="Embed for event registration form"
            className="registration_form_iframe"
            src="https://docs.google.com/forms/d/e/1FAIpQLScIy2w69PM-4b3i6BgR4PHN7CRabpl8WB7bWTuw-fro02tw_A/viewform?embedded=true"
            width="100%"
            height="4345"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
        <div className="event_details">
          <div className="event_details_info">
            <div className="event_details_header">
              <h3>Join us </h3>
            </div>
            <div className="event_details_content">
              <div>
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                <span>
                  Date: 9<sup>th</sup>March
                </span>
              </div>
              <div>
                <i class="fa fa-map-pin" aria-hidden="true"></i>
                <span>
                  Location:{" "}
                  <a href="https://www.google.com/maps/place/JKUAT+-+Karen+Campus/@-1.3558655,36.7384073,17z/data=!3m1!4b1!4m6!3m5!1s0x182f053eaa497271:0x3a7cb666d65c8bae!8m2!3d-1.3558709!4d36.7409822!16s%2Fg%2F1pp2vkdng?entry=ttu&g_ep=EgoyMDI1MDIxMC4wIKXMDSoASAFQAw%3D%3D">
                    Jkuat Karen Campus
                  </a>
                </span>
                <div />
              </div>
              <div className="event__payment_details">

                <h3>Payment Details</h3>
                <p>
                  The registration fee being charged is <strong>Ksh: 2000</strong>.
                  This money will go into preparing the t-shirts, caps, hosting the
                  event and the suplus will go into our campaign to raise awareness
                  about mental health and dementia.
                </p>
              </div>
              <div className="payment__methods">
                <h4>Payment can be made through:</h4>
                <ul className="payment__methods__list">
                  <li>MPESA Paybill Number: <strong><p style={{ color: '#a412ff' }}>8002385</p></strong></li>
                  <li><strong>Account Name:</strong> </li>
                  <li>&emsp;For Run Registration: <strong>YourName#run</strong></li>
                  <li>&emsp;For Donations: <strong>YourName#donate</strong></li>
                </ul>
              </div>
              <div className="donation_section">
                <h4>DONATE:</h4>
                <p>
                  For those who want to support the course, whether you are
                  participating or not feel free to donate through the Paybill
                  number above.
                </p>
              </div>
              <div className="event_race_details">
                <h4>Race Details</h4>
                <table>
                  <tr>
                    <th>Distance</th>
                    <th>Start Time</th>
                  </tr>
                  <tr>
                    <td>60KM</td>
                    <td>4:30am</td>
                  </tr>
                  <tr>
                    <td>42KM</td>
                    <td>6:00am</td>
                  </tr>
                  <tr>
                    <td>30KM</td>
                    <td>6:30am</td>
                  </tr>
                  <tr>
                    <td>21KM</td>
                    <td>7:00am</td>
                  </tr>
                  <tr>
                    <td>10KM</td>
                    <td>8:00am</td>
                  </tr>
                  <tr>
                    <td>5KM</td>
                    <td>8:00am</td>
                  </tr>
                </table>
                <div className="event_note">
                  <div><h4>NOTE:</h4></div>
                  <div>
                    <span><i>*All runners to arrive 15 minutes before their respective race start time.*</i></span></div>
                </div>
                <div>
                  <h4>Route</h4>
                  <p>
                    The route will be marked and there will be water points along
                    the way.
                  </p>
                </div>
                <div>
                  <h4>Rewards</h4>
                  <p>
                    There shall be{" "}
                    <i>
                      <strong>special memento</strong>
                    </i>{" "}
                    for those who dare and conqure the 60KM race
                  </p>
                </div>
                <div>
                  <h4>Medical</h4>
                  <p>
                    There will be medical personel on standby to ensure the safety
                    of all participants
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="event__location">
            <h4>Location</h4>
            <span>Refer to the map below to find you directions to JKUAT Karen Campus</span>
            <div id="map" style={{ height: "400px", width: "100%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixtyBySixty;
