import React, { useEffect } from "react";
import SixtyBySixty from "../components/60@60/60@60";
import Footer from "../components/Footer/footer";
import Navbar from "../components/Navbar/navbar";

const SixtyBySixtyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="sixtyBySixtyPage">
      <Navbar />
      <SixtyBySixty />
      <Footer />
    </div>

  )
};

export default SixtyBySixtyPage;